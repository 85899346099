<template>
  <CCardBody>
    <CButton
      color="primary"
      class="mb-2"
      :href="csvCode" 
      download="inactivehealth.csv"
      target="_blank"
      v-if="$store.state.role == 'superadmin'"
    >
      Download (.csv)
    </CButton>
     
    <CDataTable
      :items="customersData"
      :fields="fields"
      items-per-page-select
      :items-per-page="10"
      hover
      sorter
      pagination
      table-filter
      cleaner
      @filtered-items-change="setCurrentItems"
    >
    <template #certificate_no>
      <td class="">
        Nil
      </td>
    </template>
    <template #policy_no>
      <td class="">
        Nil
      </td>
    </template>
    <template #start>
      <td class="">
        Nil
      </td>
    </template>
    <template #end>
      <td class="">
        Nil
      </td>
    </template>
    </CDataTable>
  </CCardBody>
</template>

<script>
import axios from 'axios'
import url from '@/main'

const fields = [
  { key: 'firstname', _style:'min-width:30%', label: 'First Name' },
  { key: 'lastname', _style:'min-width:30%', label: 'Last Name' },
  { key: 'certificate_no', label: 'Certificate Number', _style:'min-width:20%;' },
  { key: 'policy_no', label: 'Policy Number', _style:'min-width:20%;' },
  { key: 'start', label: 'Start Date', _style:'min-width:20%;' },
  { key: 'end', label: 'End Date', _style:'min-width:20%;' },
  // 'Action'
  // {key:'is_verified', label: 'Status'},
]
const customersData = []
export default {
  data () {
    return {
      fields,
      details: [],
      collapseDuration: 0,
      customersData: [],
      currentItems: customersData.slice(),
      deleteModal: false
    }
  },
  computed: {
    csvContent () {
      return this.currentItems.map(item => Object.values(item).join(',')).join('\n')
    },
    csvCode () {
      return 'data:text/csv;charset=utf-8,SEP=,%0A' + encodeURIComponent(this.csvContent)
    }
  },
  filters:{
    formateDate(str){
      var date = new Date(str);
      var day = date.getDate();
      var year = date.getFullYear();
      var month = date.getMonth()+1;
      var dateStr = year+"/"+month+"/"+day;
      return dateStr
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case true: return 'success'
        case false: return 'secondary'
      }
    },
    setCurrentItems (val) {
      this.currentItems = val
    },
  },

  beforeCreate(){
    this.$store.commit('startLoading');
    axios.get(`${url}/policy/inactive/health`)
    .then(res=>{
      this.$store.commit('stopLoading')
      // console.log(res)
      this.customersData = res.data.data.records
    })
    .catch(err=>{
      if(err.response){
        // console.log(err.response)
        if(err.response.data.message == "Invalid or expired JWT"){
          this.$router.push('/app/login')
        }else{
          alert('Unable to load resources')
        }
      }
      this.$store.commit('stopLoading')
      // alert('Unable to load resources') 
    })
  }
}
</script>